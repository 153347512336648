import request from './request'


export function getcontentList(obj) {
    return request({
        url: '/live/client/detail',
        method: 'post',
        data: obj,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}
export function gettag(obj) {
    return request({
        url: '/live/client/content/tags',
        method: 'post',
        data: obj,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}
export function getlist(obj) {
    return request({
        url: '/live/client/content/list',
        method: 'post',
        data: obj,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}
export function getexplorerlist(obj) {
    return request({
        url: '/live/client/content/explorerList',
        method: 'post',
        data: obj,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}
export function wxconfig(obj) {
    return request({
        url: 'https://www.shopping-shanghai.com/shgw_rmt_h5_api/wx/config',
        method: 'get',
        params: obj
    })
}

export function getgenerate(obj) {
    return request({
        url: '/live/generate',
        method: 'post',
        data: obj,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}
