<template>
  <div class="kdmvp-canvas">
    <canvas v-show="canvasFlag" id="myCanvas" :width="width" :height="height">
      您的浏览器不支持 HTML5 canvas 标签。
    </canvas>
  </div>
</template>

<script>
export default {
  name: "KdmvpCanvas",
  props: {
    show: Boolean,
    canvasData: {},
  },
  watch:{
    show(){
      this.canvasFlag = this.show
    }
  },
  data() {
    return {
      myCanvas: null,
      ctx: null,
      width: this.canvasData.Size.width,
      height: this.canvasData.Size.height,
      canvasFlag: this.show,
    }
  },
  created() {
  },
  mounted() {
    this.myCanvas = document.getElementById("myCanvas");
    this.ctx = this.myCanvas.getContext("2d");
  },
  methods: {
    //condition:是否填充
    canvasRect(data, condition) {
      if (condition) {
        this.ctx.rect(data.position[0], data.position[1], data.position[2], data.position[3]);
        this.ctx.stroke();
      } else {
        this.ctx.fillStyle = "#ffffff";
        this.ctx.fillRect(data.position[0], data.position[1], data.position[2], data.position[3]);
      }
    },
    canvasText(data, condition, initX, initY, font, lineHeight, maxWidth, row) {
      if (condition) {
        var lineWidth = 0;
        var lastSubStrIndex = 0;
        this.ctx.font = data.font;
        this.ctx.fillStyle = "#000000"
        this.ctx.textAlign = "left";
        var currentRow = 1;
        maxWidth = Math.ceil(maxWidth);
        for (let i = 0; i < data.content.length; i++) {
          lineWidth += this.ctx.measureText(data.content[i]).width;
// console.log(lineWidth)
          if (lineWidth > maxWidth) {
// console.log(maxWidth, "最大宽度")
            currentRow++;
            let newStr = data.content.substring(lastSubStrIndex, i + 1)
            if (currentRow > row && data.content.length > i) {
              newStr = data.content.substring(lastSubStrIndex, i - 2) + '...'
            }
            this.ctx.fillText(newStr, data.position[0], data.position[1]);
            data.position[1] += lineHeight;
            lineWidth = 0;
            lastSubStrIndex = i + 1;
            if (currentRow > row) {
              break;
            }
          }
          if (i == data.font.length - 1) {
            this.ctx.fillText(data.content.substring(lastSubStrIndex, i + 1), data.position[0], data.position[1]);
          }
        }
      } else {
        this.ctx.font = data.font;
        this.ctx.strokeText(data.content, data.position[0], data.position[1]);
      }
    },
    canvasImageandtext(data, x, y, w, h, r) {
      this.ctx.save()
      var img = new Image();
      img.setAttribute('crossOrigin', 'anonymous')
      img.onload = () => {
        // 将图片画到canvas上面上去！
        this.ctx.drawImage(img, data.position[0], data.position[1], data.size[0], data.size[1]);

        this.ctx.beginPath()

        // 左上弧线
        this.ctx.arc(x + r, y + r, r, 1 * Math.PI, 1.5 * Math.PI)
        // 左直线
        this.ctx.moveTo(x, y + r)
        this.ctx.lineTo(x, y + h - r)
        // 左下弧线
        this.ctx.arc(x + r, y + h - r, r, 0.5 * Math.PI, 1 * Math.PI)
        // 下直线
        this.ctx.lineTo(x + r, y + h)
        this.ctx.lineTo(x + w - r, y + h)
        // 右下弧线
        this.ctx.arc(x + w - r, y + h - r, r, 0 * Math.PI, 0.5 * Math.PI)
        // 右直线
        this.ctx.lineTo(x + w, y + h - r)
        this.ctx.lineTo(x + w, y + r)
        // 右上弧线
        this.ctx.arc(x + w - r, y + r, r, 1.5 * Math.PI, 2 * Math.PI)
        // 上直线
        this.ctx.lineTo(x + w - r, y)
        this.ctx.lineTo(x + r, y)

        this.ctx.fillStyle = "#00000080";
        this.ctx.fill();
        this.ctx.font = data.font;
        this.ctx.fillStyle = "#FFFFFF"
        this.ctx.fillText(data.content, data.positiontext[0], data.positiontext[1]);
      }
      img.src = 'https://api.dgshare.com/content/explorer/ossUrl?url='+data.src;
    },
    canvasImage(data) {
      this.ctx.save()
      // 内存中先加载，然后当内存加载完毕时，再把内存中的数据填充到我们的 dom元素中，这样能够快速的去
      // 反应，比如网易的图片
      var img = new Image();
      img.onload = () => {
        this.ctx.drawImage(img, data.position[0], data.position[1], data.size[0], data.size[1]);
      }
      img.src = data.src;
    },
    /*
    * 绘制多行文本，自动换行，超出添加...
    *
    str:要绘制的字符串
    canvas:canvas对象
    initX:绘制字符串起始x坐标
    initY:绘制字符串起始y坐标
    lineHeight:字行高，自己定义个值即可
    maxWidth: 文本最大宽度
    row: 最大行数
    */
    canvasTextAutoLine(str, initX, initY, font, lineHeight, maxWidth, row) {
      this.ctx.save()
      var lineWidth = 0;
      var lastSubStrIndex = 0;
      this.ctx.font = font;
      this.ctx.fillStyle = "#000000"
      this.ctx.textAlign = "left";
      var currentRow = 1;
      maxWidth = Math.ceil(maxWidth);
      for (let i = 0; i < str.length; i++) {
        lineWidth += this.ctx.measureText(str[i]).width;
// console.log(lineWidth)
        if (lineWidth > maxWidth) {
// console.log(maxWidth, "最大宽度")
          currentRow++;
          let newStr = str.substring(lastSubStrIndex, i + 1)
          if (currentRow > row && str.length > i) {
            newStr = str.substring(lastSubStrIndex, i - 2) + '...'
          }
          this.ctx.fillText(newStr, initX, initY);
          initY += lineHeight;
          lineWidth = 0;
          lastSubStrIndex = i + 1;
          if (currentRow > row) {
            break;
          }
        }
        if (i == str.length - 1) {
          this.ctx.fillText(str.substring(lastSubStrIndex, i + 1), initX, initY);
        }
      }
    },
    canvasLine(w, x, y, z) {
      this.ctx.strokeStyle = '#000000';
      // 设置线条的宽度
      this.ctx.lineWidth = 1;

      // 绘制直线
      this.ctx.beginPath();
      // 起点
      this.ctx.moveTo(w, x);
      // 终点
      this.ctx.lineTo(y, z);
      this.ctx.closePath();
      this.ctx.stroke();
    },
    convertCanvasToImage() {
      setTimeout(() => {
        var image = new Image();
        image.setAttribute("crossOrigin",'Anonymous')
        image.src = this.myCanvas.toDataURL('image/png')//+"?v="+new Date().getTime();
        this.$parent.getimage(image.src);
      },500)
    }
  }
}
</script>

<style scoped>

.kdmvp-canvas {
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 5%;
}
</style>
