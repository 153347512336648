<template>
  <div class="canvas">
    <kdmvp-canvas :show="canvasShow" :canvasData="canvasData" ref="canvas"></kdmvp-canvas>
    <van-loading v-if="loading" size="50px" :vertical="true" color="#1989fa"/>
    <vueQr style="display: none" name="qrcodeimg" :text="downloadData.url" :margin="0" colorDark="#000000"
           colorLight="#fff"
    ></vueQr>
    <!--    :logoSrc="downloadData.icon + '?cache'" :logoScale="0.3" :size="200"-->
    <div class="btn">
      <div class="btn_g"></div>
      <div class="btn_one">
        <img width="20" src="../assets/image/Share/xz.png" alt="">
      </div>
      <div class="btn_one_text">长按保存图片</div>
      <div class="btn_two" @click="showshare=true">
        <img width="20" src="../assets/image/Share/wx.png" alt="">
      </div>
      <div class="btn_two_text" @click="showshare=true">分享好友</div>
      <div class="btn_three" @click="showshare=true">
        <img width="20" src="../assets/image/Share/pyq.png" alt="">
      </div>
      <div class="btn_three_text" @click="showshare=true">分享朋友圈</div>
    </div>
    <img class="zimg" :src="url" alt="">
    <div v-if="showshare" class="mengceng" @click="showshare=false">
      <img style="width: 195px;position: absolute;top: 10px;right: 43px" src="../assets/image/Live/sharetext.png"
           alt="">
    </div>
  </div>
</template>

<script>
import vueQr from 'vue-qr'
import KdmvpCanvas from "@/components/KdmvpCanvas";

export default {
  name: "Share",
  data() {
    return {
      showshare: false,
      downloadData: {
        url: this.$route.query.qr
      },
      loading: true,
      canvasShow: true,//是否显示canvas组件
      canvasData: {
        Size: {
          width: 500,
          height: 900
        },
        //绘制矩形
        //[x,y,width,height,]
        Rect: {
          position: [0, 0, 500, 900],
        },
        //Canvas - 文本
        //font：文字大小，content：文字内容，position：位置坐标
        Text: {
          font: "20px Alibaba",
          content: this.$route.query.title,
          position: [36, 770]
        },
        Desc: {
          font: "20px Alibaba",
          content: this.$route.query.desc,
          position: [36, 770]
        },
        //Canvas - 图像+文字
        Imagetext: {
          src: this.$route.query.img === '' ? this.$route.query.un : this.$route.query.img,
          //位置
          position: [0, 0],
          //大小
          size: [500, 500],
          font: "20px Alibaba",
          content: "长按识别观看实时直播",
          positiontext: [65, 460]
        },
        //Canvas - 图像
        Image: {
          src: this.$route.query.qr,
          //位置
          position: [396, 796],
          //大小
          size: [80, 80]
        },
      },
      url: ""
    }
  },
  created() {
  },
  mounted() {
    setTimeout(() => {
      this.canvasData.Image.src = document.getElementsByName("qrcodeimg")[0].src;
      this.$refs.canvas.canvasRect(this.canvasData.Rect, false);
      this.$refs.canvas.canvasImageandtext(this.canvasData.Imagetext, 36, 426, 260, 54, 27, this.Text);
      this.$refs.canvas.canvasTextAutoLine(this.$route.query.title, 36, 560, "36px Alibaba", 54, 260, 2);
      this.$refs.canvas.canvasLine(36, 694, 116, 694);
      this.$refs.canvas.canvasText(this.canvasData.Desc, true, 36, 522, "36px Alibaba", 25, 323, 2);
      this.$refs.canvas.canvasImage(this.canvasData.Image);
      this.$refs.canvas.convertCanvasToImage();
      this.canvasShow = false;
    }, 2000)
  },
  methods: {
    getimage(url) {
      this.url = url;
      this.loading = false;
    }
  },
  components: {
    KdmvpCanvas,
    vueQr
  },
}
</script>

<style scoped>
.van-loading {
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
}

.zimg {
  width: 250px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 5%;
  z-index: 99;
}

.mengceng {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
}

.canvas {
  width: 100%;
  height: 100%;
  background: #C3213D;
}

.btn {
  width: 375px;
  height: 125px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 20px 20px 0px 0px;
  position: fixed;
  bottom: 0;
}

.btn img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.btn_g {
  width: 110px;
  height: 5px;
  background: #FFFFFF;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.btn_one {
  width: 42px;
  height: 42px;
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid #FFFFFF;
  border-radius: 50%;
  float: left;
  margin-left: 73px;
  margin-top: 40px;
  position: relative;
}

.btn_two {
  width: 42px;
  height: 42px;
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid #FFFFFF;
  border-radius: 50%;
  float: left;
  margin-left: 48px;
  margin-top: 40px;
  position: relative;
}

.btn_three {
  width: 42px;
  height: 42px;
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid #FFFFFF;
  border-radius: 50%;
  float: left;
  margin-left: 48px;
  margin-top: 40px;
  position: relative;
}

.btn_one_text {
  font-size: 10px;
  font-family: Alibaba;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 90px;
  left: 67px;
}

.btn_two_text {
  font-size: 10px;
  font-family: Alibaba;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 90px;
  left: 171px;
}

.btn_three_text {
  font-size: 10px;
  font-family: Alibaba;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 90px;
  left: 259px;
}

.btn img {
  margin: 11px auto 0 auto;
}
</style>
