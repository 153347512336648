<template>
  <div class="block">
    <div v-show="!choice" class="block_img" v-for="(item,index) in value"
         :key="index"
         v-lazy:background-image="item.url_resize"
         @click="clickimg(index)"></div>
    <div v-show="choice" class="block_img" v-for="(item,index) in value"
         :key="'box'+index"
         v-lazy:background-image="item.url_resize"
         @click="clickbox(index,item.url)">
      <van-checkbox v-model="item.checked" icon-size="20px" checked-color="#07c160"></van-checkbox>
    </div>
  </div>
</template>

<script>
import {ImagePreview} from "vant";
export default {
  name: "Block",
  props: {
    value: null,
    choice: {
      type: Boolean
    },
  },
  data() {
    return {
      activeIcon: 'https://img.yzcdn.cn/vant/user-active.png',
      inactiveIcon: 'https://img.yzcdn.cn/vant/user-inactive.png',
      newimg: []
    }
  },
  watch: {
    choice() {
      if (!this.choice) {
        this.$emit("eliminate");
      }
    }
  },
  methods: {
    clickimg(index) {
      let newgroup = []
      this.value.forEach((item) => {
        newgroup.push(item.url)
      });
      ImagePreview({
        images: newgroup,
        startPosition: index,
      });
    },
    clickbox(index, url) {
      this.$emit("fatherWbox", index, url);
    }
  }
}
</script>

<style scoped>
.block {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.block_img {
  width: 77px;
  height: 77px;
  position: relative;
  margin-right: 8px;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
margin-bottom: 8px;
}


.block_img:nth-child(4n) {
  margin-right: 0;
}
</style>
