<template>
  <div class="home">
    <div class="home_bj">
      <div class="home_time">{{ time }}</div>
      <div class="home_btn" @click="goLive">进入直播</div>
    </div>
  </div>
</template>

<script>

// import {getwx} from "@/request/getwx";
import wx from "weixin-js-sdk";
import {getcontentList, gettag, wxconfig} from "@/request/api";

export default {
  name: "Home",
  data() {
    return {
      time: 3,//开屏倒计时
      timer: null,
      code:''
    }
  },
  created() {
  },
  destroyed() {
  },
  mounted() {
    this.getWxConfig();
    this.code = this.$route.query.code;
    this.getlistcontent();
    let that = this;
    this.timer = setInterval(() => {
      if (that.time === 0) {
        clearInterval(this.timer);
        this.$router.push({
          path: 'Live', query: {
            id: this.$route.query.id,
            code: this.$route.query.code
          }
        });
      } else {
        that.time--;
      }
    }, 1000);
  },
  methods: {
    getlistcontent() {
      let that = this;
      getcontentList({
        code: this.code
      }).then(res => {
        let data = res.data.data;
        this.weChat_data = data.weChat_shared;
        // getwx({
        //   title: that.weChat_data.weChat_shared_title,
        //   desc: that.weChat_data.weChat_shared_desc,
        //   link: "https://live.dgshare.com/?code=" + that.code,
        //   imgUrl: that.weChat_data.weChat_shared_url,
        // })
      })
    },
    goLive() {
      clearInterval(this.timer);
      this.$router.push({
        path: 'Live', query: {
          id: this.$route.query.id,
          code: this.$route.query.code
        }
      })
    },
    getWxConfig() {
      wxconfig({
        Url: window.location.href
      }).then(reswx => {
          let data = reswx.data.data;
          wx.config({
              debug: false,
              appId: data.app_id,
              timestamp: data.timestamp,
              nonceStr: data.nonce_str,
              signature: data.signature,
              jsApiList: ['updateTimelineShareData', 'updateAppMessageShareData']
          });
          wx.ready(function () {
              console.log(123)
              wx.updateTimelineShareData({
                  title: "百联股份双旦嘉年华",
                  desc: "Hello Mr New ! 百联股份双旦活动等你来！",
                  link: "https://live.dgshare.com/?code=UUUUU4",
                  imgUrl: "https://marketing-platform.oss-cn-shanghai.aliyuncs.com/go.micro.srv.explorer/2020-12/ec5fc70e1f8fef6305af9d2986aa871c/1608121834526.png",
                  success: function () {
                  }
              });
              wx.updateAppMessageShareData({
                  title: "百联股份双旦嘉年华",
                  desc: "Hello Mr New ! 百联股份双旦活动等你来！",
                  link: "https://live.dgshare.com/?code=UUUUU4",
                  imgUrl: "https://marketing-platform.oss-cn-shanghai.aliyuncs.com/go.micro.srv.explorer/2020-12/ec5fc70e1f8fef6305af9d2986aa871c/1608121834526.png",
                  success: function () {
                  }
              });
          });
      })    
    }
  },
  components: {}
};
</script>
<style lang="scss" scoped>
@import '../assets/css/home';
</style>
