<template>
  <div>
    <div :class="classes" v-for="(item,index) in value"
         :key="index">
      <div class="timeline--item-line"></div>
      <div class="timeline--item-node">
        <slot name="node">
          <div class="timeline--item-node-content">
            {{ node }}
          </div>
        </slot>
      </div>
      <div class="timeline--item-time" v-if="item.create_time || $slots.time">
        {{ item.create_time }}
      </div>
      <div class="timeline--item-body">
        <div class="timeline--item-body-wrapper">
          <div class="timeline--item-title">
            {{ item.content }}
          </div>
          <div class="timeline--item-content">
            <div v-if="item.explorer_type===1">
              <div style="display: flex;flex-wrap: wrap;" v-show="item.explorer.length>0">
                <div v-show="!choice" class="timeline_img" v-for="(itemimg,indeximg) in item.explorer"
                     :key="'-'+indeximg"
                     v-lazy:background-image="itemimg.link_resize"
                     @click="clickimg(index,indeximg)"/>
                <div v-show="choice" class="timeline_img" v-for="(itemimg,indeximg) in item.explorer"
                     :key="indeximg"
                     v-lazy:background-image="itemimg.link_resize"
                     @click="clickbox(index,indeximg,itemimg.link)">
                  <van-checkbox v-model="itemimg.checked" icon-size="20px" checked-color="#07c160"></van-checkbox>
                </div>
              </div>
            </div>
            <div class="myvideo" v-if="item.explorer_type===2">
              <!--              <video :id="'myVideo' + item.explorer[0].id" class="video-js" style="width: 100%;height: 178px">-->
              <!--                <source :src="item.explorer[0].link" type="video/mp4"/>-->
              <!--              </video>-->
              <video v-show="!item.explorer[0].cover" :id="'myVideo' + item.explorer[0].id" controls style="width: 100%;height: 178px"
                     x5-playsinline=""
                     :playsinline="true"
                     :webkit-playsinline="true"
                     :x-webkit-airplay="true"
                     x5-video-player-type="h5"
                     x5-video-player-fullscreen=""
                     x5-video-orientation="portraint">
                <source :src="item.explorer[0].link" type="video/mp4">
                您的浏览器不支持 video 标签。
              </video>
              <div v-show="item.explorer[0].cover" class="video-js-img"
                   :style="{background:'url('+item.explorer[1].link+')'}"
                   @click="getplay('myVideo' + item.explorer[0].id)">
                <img src="../assets/image/Live/play.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {ImagePreview} from 'vant';
import '@/components/style.scss'
import 'video.js/dist/video-js.css'

export default {
  name: 'TimelineItem',
  props: {
    value: null,
    node: {
      type: [String, Number]
    },
    choice: {
      type: Boolean
    },
  },
  data() {
    return {
      newimg: []
    }
  },
  watch: {
    choice() {
      if(!this.choice){
        this.$emit("eliminate");
      }
    }
  },
  methods: {
    getplay(id) {
      this.$emit("fathergetplay", id);
    },
    clickimg(index, indeximg) {
      let newgroup = []
      this.value[index].explorer.forEach(item => {
        newgroup.push(item.link)
      });
      ImagePreview({
        images: newgroup,
        startPosition: indeximg,
      });
      this.$emit("shutdown");
    },
    clickbox(index, indeximg, url) {
      this.$emit("fatherbox", index, indeximg, url);
    },
  },
  computed: {
    classes() {
      return {
        'timeline--item': true,
        [`timeline--item-position-${this.position}`]: true
      }
    }
  },
}
</script>
<style lang="scss">
.van-checkbox {
  position: absolute;
  top: 7px;
  left: 7px;
}

.timeline_img {
  width: 86px;
  height: 86px;
  margin-right: 11px;
  margin-bottom: 11px;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  position: relative;
}

.vjs-big-play-button {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
}

.video-js-img {
  width: 100%;
  height: 178px;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  z-index: 9;

  img {
    position: absolute;
    width: 39px;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
  }
}
</style>
