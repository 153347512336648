<template>
  <div class="live">
    <van-swipe @change="onChange" :autoplay="5000">
      <van-swipe-item class="live_swipe_img" v-for="(item,index) in swipedata" :key="index">
        <img :src="item.show_url" alt="">
      </van-swipe-item>
    </van-swipe>
    <div class="live_content">
      <div class="live_content_title">{{ live.title }}</div>
      <div class="live_content_people">
        <van-icon class="live_content_logo" name="friends"/>
        <p class="live_content_num"><span style="font-weight: bold">{{ live.num }}</span>人</p>
      </div>
      <div class="live_content_time">
        <van-icon class="live_content_logo" name="clock"/>
        <p class="live_content_num">{{ live.time }}</p>
      </div>
      <div class="more" @click="pop.showpop=true">更多信息</div>
    </div>
    <div class="live_online">
      <div class="live_online_label" @click="onlabel(0)">
        {{ online.label }}
        <img width="8px" src="../assets/image/Live/more.png" alt="">
      </div>
      <div class="live_online_sort" @click="opensort(1)">
        {{ online.sort }}
        <img width="8px" src="../assets/image/Live/more.png" alt="">
      </div>
      <div class="live_online_tag">
        <van-icon class="live_online_tag_more" name="descending" @click="cut_tabs=!cut_tabs"/>
        <div class="live_online_tag_line"></div>
        <ul :class="cut_tabs?'tabs':'tabscut'">
          <li v-for="(item,index) in tabs" :key="index" :class="['tab-list',item.click?'tab-list-ok':'']"
              @click="tabschick(index)">
            {{ item.name }}
            <div v-show="item.click" class="bon"></div>
          </li>
        </ul>
      </div>
      <Timeline v-if="online.sort==='时间线'" line-position="left" direction="vertical" fill-parent fill-node>
        <TimelineItem
            v-model="timelinedata"
            :choice="choice"
            @fatherbox="fatherbox"
            @eliminate="eliminate"
            @fathergetplay="fathergetplay"
            @shutdown="shutdown"/>
      </Timeline>
      <Waterfall v-if="online.sort==='瀑布流'" :choice="choice" v-model="WaterFallimg" @eliminate="eliminate"
                 @fatherWbox="fatherWbox" @shutdown="shutdown"/>
      <Block v-if="online.sort==='方格图'" :choice="choice" ref="Block" v-model="WaterFallimg" @eliminate="eliminate"
             @fatherWbox="fatherWbox" @shutdown="shutdown"></Block>
      <div style="width:141px;margin: 0 auto;">
        <img style="width:100%;" v-if="tagnimg" src="../assets/image/Live/nodata.png" alt="">
      </div>
    </div>
    <div v-show="!choice">
      <img v-if="!btnshow" class="zhankai" src="../assets/image/Live/zhankai.png" @click="btnshow=true" alt="">
      <!--      <img v-if="btnshow" class="huodong" src="../assets/image/Live/huodong.png" @click="getbtn(0)" alt="">-->
      <img v-if="btnshow" class="fenxiang" src="../assets/image/Live/fenxiang.png" @click="getbtn(1)" alt="">
      <img v-if="btnshow" class="xiazai" src="../assets/image/Live/xiazai.png" @click="getbtn(2)" alt="">
      <img v-if="btnshow" class="shouqi" src="../assets/image/Live/shouqi.png" @click="btnshow=false" alt="">
    </div>

    <div v-show="choice" class="ok" @click="getbtn(3)">确认选择</div>
    <div v-show="choice" class="no" @click="getbtn(4)">取消选择</div>
    <van-action-sheet
        v-model="show"
        :actions="actions"
        cancel-text="取消"
        close-on-click-action
        @cancel="onCancel"
        @select="onSelect"
    />
    <van-popup v-model="pop.showpop" round position="bottom" :style="{ height: '40%' }">
      <div class="pop_top_tap">
        <div class="pop_top_tap_in" v-for="(item,index) in pop.toptap" :key="index" @click="clicktap(index)">
          <p style="position: absolute;z-index: 99;width: 100%">
            {{ item.name }}
          </p>
          <div v-if="item.check" class="pop_top_tap_bottom"></div>
        </div>
      </div>
      <div class="pop_top_tap_title">
        {{ pop.title }}
      </div>
      <div class="pop_top_tap_time">
        <van-icon name="clock" style="margin-right: 6px"/>
        <p>{{ pop.time }}</p>
      </div>
      <!--      <div class="pop_top_tap_time">-->
      <!--        <van-icon name="location" style="margin-right: 6px"/>-->
      <!--        <p>{{ pop.address }}</p>-->
      <!--      </div>-->
      <div class="pop_top_tap_time">
        <van-icon name="column" style="margin-right: 6px"/>
        <p style="max-width: 309px;line-height: 20px">{{ pop.desc }}</p>
      </div>
    </van-popup>
    <div class="tip" v-if="showtip" @click="showtip=false">
      <img src="../assets/image/Live/tipdow.png" alt="">
    </div>
  </div>
</template>

<script>
import Timeline from '@/components/Timeline'
import TimelineItem from '@/components/TimelineItem'
import Waterfall from "@/components/waterfall";
import Block from "@/components/Block";
import {getcontentList, gettag, getlist, getexplorerlist, getgenerate, wxconfig} from "@/request/api";
import {getwx} from "@/request/getwx";
import JSZip from "jszip";
import FileSaver from "file-saver";
import axios from 'axios'
import wx from "weixin-js-sdk";

const getFileimg = url => {
  return new Promise((resolve, reject) => {
    let obj = {
      method: 'get',
      url: 'https://api.dgshare.com/content/explorer/ossUrl?url=' + url,
      // responseType: 'blob''arrayBuffer'
      responseType: 'blob',
      withCredentials: false
    }
    axios(obj)
        .then(data => {
          console.log(data)
          resolve(data.data)
        })
        .catch(error => {
          reject(error.toString())
        })
  })
}
export default {
  name: "Live",
  data() {
    return {
      tagnimg: false,//是否展示暂无数据
      tagntext: false,//到底了
      showshare: false,
      btnshow: false,
      choice: false,
      col: 2,
      type: 1,//类型 1 图片 2 视频 3 音频
      pop: {
        desc: "",
        time: "",
        address: "",
        title: "",
        toptap: [
          {
            check: true,
            name: "直播信息"
          }
        ],
        showpop: false,
      },
      time: new Date().toLocaleString(),
      length: 3,
      show: false,
      actions: [{name: '瀑布流'}, {name: '时间线'}, {name: '方格图'}],
      current: 0,
      live_id: 0,
      swipedata: [],
      live: {
        title: "",
        num: null,
        time: ""
      },
      online: {
        label: "全部",
        sort: "瀑布流",
      },
      cut_tabs: true,
      tabs: [{
        click: true,
        name: "全部"
      }],
      timelinedata: [],
      WaterFallimg: [],
      nowtag: "",
      Waterpage: 0,
      page: 0,
      page_size: 5,
      status: 1,
      scrolldata: false,
      videoarr: [],
      videoclass: [],
      actkey: -1,
      newimg: [],
      showtip: false,
      weChat_data: null,
      token: "",
      videoid: "",
      code: ""
    }
  },
  computed: {
    itemWidth() {
      return (138 * 0.5 * (document.documentElement.clientWidth / 375))  //#rem布局 计算宽度
    },
    gutterWidth() {
      return (9 * 0.5 * (document.documentElement.clientWidth / 375))	//#rem布局 计算x轴方向margin(y轴方向的margin自定义在css中即可)
    }
  },
  created() {
    for (var i = 0; i < 8; i++) {
      this.token += Math.floor(Math.random() * 10);
    }
    this.code = this.$route.query.code;
    this.getlistcontent();
  },
  destroyed() {
    this.websock.close() //离开路由之后断开websocket连接
  },
  mounted() {
    this.getWxConfig()
    window.addEventListener('scroll', () => {
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if (scrollTop + windowHeight + 5 > scrollHeight) {
        this.scrolldata = true;
      } else {
        this.scrolldata = false;
      }
    }, true);
  },
  methods: {
    initWebSocket() { //初始化weosocket
      setTimeout(() => {
        this.live.num++;
      }, 1000)

      function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      }

      let uuid = S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4();
      const wsuri = "wss://api.dgshare.com/message/ws?key=u" + this.token + "|" + uuid;
      //ws://10.0.0.20:8080/
      //wss://api.dgshare.com/
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    websocketonopen() { //连接建立之后执行send方法发送数据
      let data = {type: 1, data: {live_id: this.live_id, status: 1}}
      this.websocketsend(JSON.stringify(data));
    },
    websocketonerror() {//连接建立失败重连
      this.initWebSocket();
    },
    websocketonmessage(e) { //数据接收
      const redata = JSON.parse(e.data);
      let data = JSON.parse(redata.content);
      switch (redata.type) {
        case 1://新增
          console.log(redata)
          console.log(data)
          if (redata.live_id === this.live_id) {
            if (data.explorer != undefined) {
              let data_explorer = data.explorer;
              for (let j = 0; j < data_explorer.length; j++) {
                let a = {
                  url: data_explorer[j].link,
                  url_resize: data_explorer[j].link_resize,
                  checked: false
                }
                data.explorer[j].id = data_explorer[j].id;
                data.explorer[j].url = data_explorer[j].link;
                data.explorer[j].url_resize = data_explorer[j].link_resize;
                data.explorer[j].checked = false;
                this.WaterFallimg.unshift(a)
              }
            }
            this.timelinedata.unshift(data);
          }

          break;
        case 2:
          break;
      }
    },
    websocketsend(Data) {//数据发送
      this.websock.send(Data);
    },
    websocketclose(e) {  //关闭
      console.log('断开连接', e);
    },
    eliminate() {
      let peliminate = this.timelinedata;
      let weliminate = this.WaterFallimg;
      for (let i = 0; i < peliminate.length; i++) {
        if (peliminate[i].explorer.length > 0) {
          for (let j = 0; j < peliminate[i].explorer.length; j++) {
            peliminate[i].explorer[j].checked = false;
          }
        }
      }
      if (weliminate.length > 0) {
        for (let j = 0; j < weliminate.length; j++) {
          weliminate[j].checked = false;
        }
      }
      this.timelinedata = peliminate;
      this.WaterFallimg = weliminate;
      this.newimg = [];
    },
    fatherWbox(index, url) {
      if (this.WaterFallimg[index].checked) {
        this.WaterFallimg[index].checked = false;
        Array.prototype.remove = function (val) {
          var index = this.indexOf(val);
          if (index > -1) {
            this.splice(index, 1);
          }
        };
        this.newimg.remove(url);
        // this.newimg.remove(id);
      } else {
        this.WaterFallimg[index].checked = true;
        this.newimg.push(url)
      }
    },
    fatherbox(index, indeximg, url) {
      if (this.timelinedata[index].explorer[indeximg].checked) {
        this.timelinedata[index].explorer[indeximg].checked = false;
        Array.prototype.remove = function (val) {
          var index = this.indexOf(val);
          if (index > -1) {
            this.splice(index, 1);
          }
        };
        this.newimg.remove(url);
      } else {
        this.timelinedata[index].explorer[indeximg].checked = true;
        this.newimg.push(url)
      }
    },
    getbtn(btn) {
      const zip = new JSZip()
      const cache = {}
      const promises = []
      switch (btn) {
        case 0:
          break;
        case 1:
          this.$router.push({
            path: 'Share', query: {
              un: "https://marketing-platform.oss-cn-shanghai.aliyuncs.com/go.micro.srv.explorer/2020-12/c1c834db36ad6aef04c3ce594ea91194/%E6%B5%B7%E6%8A%A5.png",
              img: this.weChat_data.poster_url,
              qr: this.weChat_data.weChat_shared_code,
              title: this.live.title,
              desc: this.pop.desc,
            }
          })
          // this.showshare = true;
          break;
        case 2:
          var ua = window.navigator.userAgent.toLowerCase();
          if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            this.showtip = true;
          } else {
            this.choice = !this.choice;
          }
          break;
        case 3:
          if (this.newimg.length > 0) {
            this.newimg.forEach(item => {
              const promise = getFileimg(item).then(data => {
                const arr_name = item.split('/') // 下载文件, 并存成ArrayBuffer对象
                const file_name = arr_name[arr_name.length - 1] // 获取文件名
                zip.file(file_name, data, {binary: true}) // 逐个添加文件
                cache[file_name] = data
              })
              promises.push(promise)
            })
            Promise.all(promises).then(() => {
              zip.generateAsync({type: 'blob'}).then(content => {
                // 生成二进制流
                FileSaver.saveAs(content, '图片.zip') // 利用file-saver保存文件  自定义文件名
                this.choice = false;
              })
            })
          } else {
            alert("未选择任何图片")
          }
          break;
        case 4:
          this.choice = false;
          break;
        default:
          break;
      }
    },
    getlistexplorerList(tag, operation) {
      if (operation === "add") {
        this.Waterpage++;
      } else {
        this.Waterpage = 1
      }
      getexplorerlist({
        page: this.Waterpage,
        page_size: this.page_size + 50,
        live_id: this.live_id,
        type: this.type,
        tag: tag,
      }).then(res => {
        let datasort = res.data.data;
        if (datasort.length > 0) {
          if (operation === "reset") {
            this.WaterFallimg = [];
          }
          for (let i = 0; i < datasort.length; i++) {
            let a = {
              url: datasort[i].explorer_url,
              url_resize: datasort[i].explorer_url_resize,
              checked: false
            };
            this.WaterFallimg.push(a)
          }
        }
        if (operation === "reset" && datasort.length === 0) {
          this.WaterFallimg = [];
          this.tagnimg = true;
        }
        if (this.online.sort === ("瀑布流" || "方格图") && this.WaterFallimg.length === 0) {
          this.tagnimg = true;
        }
      })
    },
    clicktap(index) {
      for (let i = 0; i < this.toptap.length; i++) {
        this.toptap[i].check = false;
      }
      this.toptap[index].check = true;
    },
    getcontent(tag, operation, all) {
      if (operation === "add") {
        this.page++;
      } else {
        this.page = 1
      }
      getlist({
        page: this.page,
        page_size: this.page_size,
        live_id: this.live_id,
        sort: 1,
        status: this.status,
        tag: tag,
      }).then(res => {
        let datasort = res.data.data.list;
        console.log(operation)
        if (datasort.length > 0) {
          let datas = [];
          if (all === '图片') {
            for (let i = 0; i < datasort.length; i++) {
              if (datasort[i].explorer_type === 1) {
                datas.push(datasort[i])
              }
            }
            if (datas.length === 0) {
              this.getcontent(this.nowtag, "add", this.online.label);
            } else {
              this.getdata(datas, operation)
            }
          } else if (all === '视频') {
            for (let i = 0; i < datasort.length; i++) {
              if (datasort[i].explorer_type === 2) {
                datas.push(datasort[i])
              }
            }
            console.log(datas)
            if (datas.length === 0) {
              this.getcontent(this.nowtag, "add", this.online.label);
            } else {
              this.getdata(datas, operation)
            }
          } else {
            for (let i = 0; i < datasort.length; i++) {
              datas.push(datasort[i])
            }
            if (datas.length < 5) {
              this.getdata(datas, "add")
              // this.getcontent(this.nowtag, "add", this.online.label);
            } else {
              this.getdata(datas, operation)
            }
          }
        } else {
          if (operation !== 'add') {
            this.timelinedata = [];
          } else if (this.timelinedata.length === 0) {
            this.timelinedata = [];
            this.tagnimg = true;
          }
        }
        if (this.online.sort === ("瀑布流" | "方格图") && this.WaterFallimg.length === 0) {
          this.tagnimg = true;
        }
      })
    },
    getdata(datas, operation) {
      if (datas.length > 0) {
        if (operation === "reset") {
          this.page = 0;
          this.timelinedata = [];
          let data = datas;
          for (let i = 0; i < data.length; i++) {
            let newlist = data[i];
            if (newlist.explorer != null) {
              let data_explorer = newlist.explorer;
              for (let j = 0; j < data_explorer.length; j++) {
                newlist.explorer[j].id = data_explorer[j].id;
                newlist.explorer[j].url = data_explorer[j].link;
                newlist.explorer[j].url_resize = data_explorer[j].link_resize;
                newlist.explorer[j].cover = true;
                newlist.explorer[j].checked = false;
              }
            }
            this.screen(i, newlist);
          }
          if (data.length === 0) {
            this.tagnimg = true;
          }
        } else if (operation === "add") {
          let data = datas;
          if (this.page === 1) {
            this.timelinedata = [];
          }
          for (let i = 0; i < data.length; i++) {
            let newlist = data[i];
            if (newlist.explorer != null) {
              let data_explorer = newlist.explorer;
              for (let j = 0; j < data_explorer.length; j++) {
                newlist.explorer[j].id = data_explorer[j].id;
                newlist.explorer[j].url = data_explorer[j].link;
                newlist.explorer[j].url_resize = data_explorer[j].link_resize;
                newlist.explorer[j].cover = true;
                newlist.explorer[j].checked = false;
              }
            }
            this.timelinedata.push(newlist);
          }
        }
      } else {
        if (this.page === 1) {
          this.timelinedata = [];
        }
      }
    },
    screen(i, newlist) {
      if (this.online.label === "全部") {
        this.timelinedata.push(newlist);
      } else if (this.online.label === "图片") {
        if (newlist.explorer_type === 1) {
          this.timelinedata.push(newlist);
        }
      } else if (this.online.label === "视频") {
        if (newlist.explorer_type === 2) {
          this.timelinedata.push(newlist);
        }
      }
    },
    opensort(key) {//1
      this.actkey = key;
      this.show = true;
      if (this.online.label === '视频') {
        this.actions = [{name: '时间线'}];
      } else if (this.online.label === '图片') {
        this.actions = [{name: '瀑布流'}, {name: '方格图'}];
      } else {
        this.actions = [{name: '瀑布流'}, {name: '时间线'}, {name: '方格图'}];
      }
    },
    onlabel(key) {//0
      this.actkey = key;
      this.actions = [{name: '全部'}, {name: '图片'}, {name: '视频'}]
      this.show = true;
    },
    onSelect(item) {
      this.choice = false;
      switch (this.actkey) {
        case 0:
          this.online.label = item.name;
          if (this.online.label === '视频') {
            this.actions = [{name: '时间线'}];
            this.online.sort = this.actions[0].name;
          } else if (this.online.label === '图片') {
            this.actions = [{name: '瀑布流'}, {name: '方格图'}];
            this.online.sort = this.actions[0].name;
          } else {
            this.actions = [{name: '瀑布流'}, {name: '时间线'}, {name: '方格图'}];
            this.online.sort = this.actions[0].name;
          }
          break;
        case 1:
          this.online.sort = item.name;
          break;
        default:
          break;
      }
    },
    onCancel() {
      console.log("取消")
    },
    onChange(index) {
      this.current = index;
    },
    getWxConfig() {
      wxconfig({
        Url: window.location.href
      }).then(reswx => {
          let data = reswx.data.data;
          wx.config({
              debug: false,
              appId: data.app_id,
              timestamp: data.timestamp,
              nonceStr: data.nonce_str,
              signature: data.signature,
              jsApiList: ['updateTimelineShareData', 'updateAppMessageShareData']
          });
          wx.ready(function () {
              console.log(321)
              wx.updateTimelineShareData({
                  title: "百联股份双旦嘉年华",
                  desc: "Hello Mr New ! 百联股份双旦活动等你来！",
                  link: "https://live.dgshare.com/?code=UUUUU4",
                  imgUrl: "https://marketing-platform.oss-cn-shanghai.aliyuncs.com/go.micro.srv.explorer/2020-12/ec5fc70e1f8fef6305af9d2986aa871c/1608121834526.png",
                  success: function () {
                  }
              });
              wx.updateAppMessageShareData({
                  title: "百联股份双旦嘉年华",
                  desc: "Hello Mr New ! 百联股份双旦活动等你来！",
                  link: "https://live.dgshare.com/?code=UUUUU4",
                  imgUrl: "https://marketing-platform.oss-cn-shanghai.aliyuncs.com/go.micro.srv.explorer/2020-12/ec5fc70e1f8fef6305af9d2986aa871c/1608121834526.png",
                  success: function () {
                  }
              });
          });
      })    
    },
    getlistcontent() {
      let that = this;
      getcontentList({
        code: this.code
      }).then(res => {
        let data = res.data.data;
        this.live_id = res.data.data.id;
        console.log(this.live_id)
        gettag({
          live_id: this.live_id,
        }).then(restag => {
          if (restag.data.data.tags !== undefined) {
            for (let i = 0; i < restag.data.data.tags.length; i++) {
              let a = {
                click: false,
                name: restag.data.data.tags[i]
              }
              that.tabs.push(a)
            }
          }
        })
        this.getcontent(this.nowtag, "add", this.online.label);
        this.getlistexplorerList(this.nowtag, "add", this.online.label);
        this.initWebSocket();
        this.weChat_data = data.weChat_shared;
        this.swipedata = data.banners;
        this.live.title = data.liveInfo.title;
        this.live.num = data.liveInfo.watch_num;
        this.live.time = data.liveInfo.start_time.split(" ")[0] + ' — ' + data.liveInfo.end_time.split(" ")[0];
        this.pop.title = data.liveInfo.title;
        this.pop.time = data.liveInfo.start_time.split(" ")[0] + ' — ' + data.liveInfo.end_time.split(" ")[0];
        this.pop.desc = data.liveInfo.desc;
        // getwx({
        //   title: that.weChat_data.weChat_shared_title,
        //   desc: that.weChat_data.weChat_shared_desc,
        //   link: "https://live.dgshare.com/?code=" + that.code,
        //   imgUrl: that.weChat_data.weChat_shared_url,
        // })
      })
    },
    tabschick(idx) {
      this.cut_tabs = true;
      this.tagnimg = false;
      this.nowtag = (this.tabs[idx].name === "全部" ? '' : this.tabs[idx].name);
      this.tabs.forEach((item, index) => {
        idx === index ? item.click = true : item.click = false
      });
      console.log(this.nowtag, "reset", this.online.label)
      this.getcontent(this.nowtag, "reset", this.online.label);
      this.getlistexplorerList(this.nowtag, "reset", this.online.label);
    },
    shutdown() {
      if (this.videoid) {
        let video = document.getElementById(this.videoid);
        video.pause();
      }
    },
    fathergetplay(id) {
      let that = this;
      let cover = that.timelinedata;
      for (let i = 0; i < cover.length; i++) {
        if (cover[i].explorer_type === 2) {
          if (id === 'myVideo' + cover[i].explorer[0].id) {
            this.videoid = 'myVideo' + cover[i].explorer[0].id;
            let video = document.getElementById('myVideo' + cover[i].explorer[0].id);
            video.play();
            cover[i].explorer[0].cover = false;
            that.timelinedata = cover;
            video.ontimeupdate = function () {
              //false 点击了播放  true 点击了暂停
              video.paused ? (cover[i].explorer[0].cover = true,
                  that.timelinedata = cover) : (cover[i].explorer[0].cover = false,
                  that.timelinedata = cover)
            };
          } else {
            let video = document.getElementById('myVideo' + cover[i].explorer[0].id);
            video.pause();
            cover[i].explorer[0].cover = true;
            that.timelinedata = cover;
            video.ontimeupdate = function () {
              //false 点击了播放  true 点击了暂停
              console.log(video.paused);
              video.paused ? (cover[i].explorer[0].cover = true,
                  that.timelinedata = cover) : (cover[i].explorer[0].cover = false,
                  that.timelinedata = cover)
            };
          }
        }
      }
      console.log(this.timelinedata)
    },
  },
  watch: {
    scrolldata() {
      if (this.scrolldata) {
        this.getcontent(this.nowtag, "add", this.online.label);
        this.getlistexplorerList(this.nowtag, "add");
      }
    },
    "online.label": {
      handler() {
        this.tagnimg = false;
        if (this.online.label === "视频") {
          this.nowtag = "";
          this.tabs.forEach((item, index) => {
            if(index === 0){
              item.click=true
            }else {
              item.click=false
            }
          });
          this.getcontent(this.nowtag, "reset", this.online.label);
        }

        if (this.online.sort === ("瀑布流" || "方格图") && this.WaterFallimg.length === 0) {
          this.tagnimg = true;
        }
        console.log(this.online.label)
      }
    },
    "online.sort": {
      handler() {
        this.tagnimg = false;
        if (this.online.sort === ("瀑布流" || "方格图") && this.WaterFallimg.length === 0) {
          this.tagnimg = true;
        }
      }
    },
  },
  components: {
    Timeline,
    TimelineItem,
    Waterfall,
    Block
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/css/live';
</style>
