<template>
  <div class="container-water-fall">
    <waterfall :isTransition="false" :col="col" :data="value" :width="itemWidth" :gutterWidth="gutterWidth">
      <template>
        <div class="cell-item" v-for="(item,index) in value" :key="index">
          <img v-show="!choice" @click="clickimg(index)" v-lazy="item.url_resize" alt="加载错误">
          <div class="cell-item-div" @click="clickbox(index,item.url)">
            <img v-show="choice" v-lazy="item.url_resize" alt="加载错误">
            <van-checkbox v-show="choice" v-model="item.checked" icon-size="20px"
                          checked-color="#07c160"></van-checkbox>
          </div>
        </div>
      </template>
    </waterfall>
  </div>
</template>

<script>
/*
  注意:
  1.itemWidth需要与gutterWidth一起使用才会生效，否则会进行自适应宽度
  2.使用了waterfall的组件不允许使用scoped,否则样式会有问题
*/
import {ImagePreview} from "vant";

export default {
  props: {
    value: null,
    choice: {
      type: Boolean
    },
  },
  data() {
    return {
      col: 2,
      loading: false,
      imggroup: []
    };
  },
  methods: {
    clickimg(index) {
      let newgroup = []
      this.value.forEach((item) => {
        newgroup.push(item.url)
      });
      ImagePreview({
        images: newgroup,
        startPosition: index,
      });
    },
    clickbox(index, url) {
      this.$emit("fatherWbox", index, url);
    },
  },
  computed: {
    itemWidth() {
      return 158;
    },
    gutterWidth() {
      return 18;
    }
  },
  watch: {
    choice() {
      if (!this.choice) {
        this.$emit("eliminate");
      }
    }
  },
};
</script>
<style lang="scss" scoped>
* {
  margin: 0;
}

.container-water-fall {
  width: 100%;
  box-sizing: border-box;

  .cell-item {
    width: 100%;
    margin-bottom: 18px;
    border-radius: 2px;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;

    img {
      // border-radius: 12px 12px 0 0;
      width: 100%;
      height: auto;
      display: block;
    }
  }
}
</style>
